
.nav-active-text {
    color: #6699CC;
    @extend .font-weight-bolder;
}


.nav-top-text {
    font-size: 1.1rem;

    &:hover {
        @extend .nav-active-text;
    }
}

.nav-top-button {
    margin-left: 10px;
    margin-right: 10px;
}

.navbar {
    @include media-breakpoint-up(lg) {
        padding-top: 20px;
    }
}

a.navmenu-item {

    padding-top: 12px;
    padding-left: 20px!important;

    span {
        @extend .font-weight-bold;
        color: map-get($pycon-colours, 'button-text');
        font-size: 18px;
    }
}

a.navmenu-item-selected {
    @extend a.navmenu-item;
    span {
        color: map-get($pycon-colours, 'links');
    }
}

