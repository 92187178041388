
.sponsor-header {
    @extend .text-uppercase;
    @extend .font-weight-bold;

    color: map-get($pycon-colours, 'button-red-hover');
    background-color: white;
    border-bottom: none;
}

.sponsor-card-title {
    color: map-get($pycon-colours, 'color-a');
}
