
a.blue-button {

    background-color: map-get($pycon-colours, 'button-blue-regular');
    border-radius: 0.25rem;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 12px!important;
    padding-right: 12px!important;

    &:hover {
        background-color: map-get($pycon-colours, 'button-blue-hover');
        text-decoration: none;
    }

    span {
        @extend .font-weight-bold;
        color: map-get($pycon-colours, 'button-text');
        font-size: 18px;
    }
}

a.red-button {

    background-color: map-get($pycon-colours, 'button-red-regular');
    border-radius: 0.25rem;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 12px!important;
    padding-right: 12px!important;

    &:hover {
        background-color: map-get($pycon-colours, 'button-red-hover');
        text-decoration: none;
    }

    span {
        @extend .font-weight-bold;
        color: white;
        font-size: 18px;
    }
}

a.invisible-button {
    @extend a.blue-button;
    background-color: transparent;

    &:hover {
        background-color: transparent;
    }
}